import { React } from 'react';
import { defaultAllTrailtsToSnowmobileType } from '../../../components/Prod/Migrations/DefaultAllTrailsToSnowmobileType';
import { defaultAllTripsToSnowmobileType } from '../../../components/Prod/Migrations/DefaultAllTripsToSnowmobileType';
import { deleteAllATVTrails } from '../../../components/Prod/Migrations/DeleteAllATVTrails';
import { resetAllUsersTOSAgreements } from '../../../components/Prod/Migrations/ResetAllUsersTOSAgreements';
import './Migrations.css';
import { deleteAllTrailsClubsAndTrips } from '../../../components/Prod/Migrations/DeleteAllTrailsClubsAndTrips';

const MigrationCard = ({ title, description, migration }) => {
    return (
        <div className="card">
            <h2>{title}</h2>
            <p>{description}</p>
            <button onClick={migration}>Run Migration</button>
        </div>
    );
}

const Migrations = () => {
    return (
        <div>
            <h1>Migrations</h1>
            <p>These are tools to fix/update data in production. Don't run these unless you are sure you know what you're doing with them. </p>

            <MigrationCard title="Default All Trails to Snowmobile Type" description="Takes all trails and if they don't have a trail_type field set, set it to snowmobile." migration={defaultAllTrailtsToSnowmobileType} />
            <MigrationCard title="Default All Trips to Snowmobile Type" description="Takes all trips and if they don't have a trip_type field set, set it to snowmobile." migration={defaultAllTripsToSnowmobileType} />
            <MigrationCard title="Delete all ATV Trails" description="Deletes all trails with a trail_type of ATV." migration={deleteAllATVTrails} />
            <MigrationCard title="Reset All Users TOS Agreements" description="Resets the TOS agreement flag for all users. Done to trigger a resign upon update of the TOS." migration={resetAllUsersTOSAgreements} />
            {/* <MigrationCard title="Delete All Trails, Clubs, and Trips" description="Deletes ALL trails, clubs, and trips. MAKE SURE TO HAVE A BACKUP." migration={deleteAllTrailsClubsAndTrips} /> */}
        </div>
    );
}

export default Migrations;